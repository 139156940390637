export var Config = (function () {
    // -------------------------------------
    // REQUIRED
    // Available at https://dev.facetec.com/#/account
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    // var DeviceKeyIdentifier = "d2oJKsE1sGVPgAZ5gGWaR7e1H0D2bOZV";
    var DeviceKeyIdentifier = "dF2CabwQ6OCLFJaV2QqZhP7OUErHv0uz";


    // -------------------------------------
    // REQUIRED
    // The URL to call to process FaceTec SDK Sessions.
    // In Production, you likely will handle network requests elsewhere and without the use of this variable.
    // See https://dev.facetec.com/#/security-best-practices?link=facetec-server-rest-endpoint-security for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    // var BaseURL = "https://liveness5.certiface.com.br";
    var BaseURL = "https://3dliveness.certiface.com.br";
    var BaseURLFacecaptcha = "https://www.certiface.com.br/facecaptcha";

    // -------------------------------------
    // REQUIRED
    // The FaceMap Encryption Key you define for your application.
    // Please see https://dev.facetec.com/#/keys?link=keys for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    var PublicFaceScanEncryptionKey =
        "-----BEGIN PUBLIC KEY-----\n" +
        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5PxZ3DLj+zP6T6HFgzzk\n" +
        "M77LdzP3fojBoLasw7EfzvLMnJNUlyRb5m8e5QyyJxI+wRjsALHvFgLzGwxM8ehz\n" +
        "DqqBZed+f4w33GgQXFZOS4AOvyPbALgCYoLehigLAbbCNTkeY5RDcmmSI/sbp+s6\n" +
        "mAiAKKvCdIqe17bltZ/rfEoL3gPKEfLXeN549LTj3XBp0hvG4loQ6eC1E1tRzSkf\n" +
        "GJD4GIVvR+j12gXAaftj3ahfYxioBH7F7HQxzmWkwDyn3bqU54eaiB7f0ftsPpWM\n" +
        "ceUaqkL2DZUvgN0efEJjnWy5y1/Gkq5GGWCROI9XG/SwXJ30BbVUehTbVcD70+ZF\n" +
        "8QIDAQAB\n" +
        "-----END PUBLIC KEY-----";

    // -------------------------------------
    // Convenience method to initialize the FaceTec SDK.
    // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
    function initializeFromAutogeneratedConfig(FaceTecSDK, callback) {
        // FaceTecSDK.initializeInDevelopmentMode(this.DeviceKeyIdentifier, this.PublicFaceScanEncryptionKey,
        //     function (initializedSuccessfully) {
        //         callback(initializedSuccessfully);
        //     });
    };

    // This app can modify the customization to demonstrate different look/feel preferences
    // NOTE: This function is auto-populated by the FaceTec SDK Configuration Wizard based on your UI Customizations you picked in the Configuration Wizard GUI.
    function retrieveConfigurationWizardCustomization(FaceTecSDK) {
        var defaultCustomization = new FaceTecSDK.FaceTecCustomization();
        this.currentCustomization = defaultCustomization;
        return defaultCustomization;
    };

    var currentCustomization;

    // -------------------------------------
    // Boolean to indicate the FaceTec SDK Configuration Wizard was used to generate this file.
    // In this Sample App, if this variable is true, a "Config Wizard Theme" will be added to this App's Design Showcase,
    // and choosing this option will set the FaceTec SDK UI/UX Customizations to the Customizations that you selected in the
    // Configuration Wizard.
    var wasSDKConfiguredWithConfigWizard = false;

    return {
        wasSDKConfiguredWithConfigWizard,
        DeviceKeyIdentifier,
        BaseURL,
        BaseURLFacecaptcha,
        PublicFaceScanEncryptionKey,
        initializeFromAutogeneratedConfig,
        currentCustomization,
        retrieveConfigurationWizardCustomization
    };

})();
